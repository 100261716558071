"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Flag.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgFlag = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsxs("g", { clipPath: "url(#flag_svg__a)", children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 1.5,
            d: "M5 27V6M5 21c8-6.929 14 6.929 22 0V6C19 12.929 13-.93 5 6"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            fill: "currentColor",
            d: "M27.338 14.391a.95.95 0 1 0-1.343-1.344l1.343 1.344ZM5.333 12.386l.39.867h-.001l.003-.001.02-.009.093-.039c.085-.034.215-.084.382-.143.335-.117.818-.267 1.398-.393 1.171-.253 2.679-.396 4.152-.029l.46-1.843c-1.86-.464-3.686-.272-5.015.016a13.82 13.82 0 0 0-1.623.456 10.725 10.725 0 0 0-.63.244c-.005.002-.01.003-.012.005a.054.054 0 0 1-.004.002h-.002l.39.867Zm6.437.253c1.526.38 2.709 1.128 4.212 1.902 1.47.758 3.13 1.461 5.351 1.461v-1.9c-1.778 0-3.119-.548-4.481-1.25-1.33-.685-2.815-1.606-4.622-2.056l-.46 1.843Zm9.563 3.363c2.107 0 3.562-.351 4.52-.734a5.837 5.837 0 0 0 1.07-.55 3.493 3.493 0 0 0 .395-.307l.011-.011.005-.005.002-.002h.001c0-.001.001-.002-.67-.674a62.046 62.046 0 0 1-.67-.673l.002-.002.003-.003a.2.2 0 0 1 .005-.005l.005-.005-.014.012c-.02.017-.062.05-.13.094a3.955 3.955 0 0 1-.72.367c-.71.283-1.922.598-3.815.598v1.9Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeWidth: 1.9,
            d: "M13.333 5.333v14"
          }
        )
      ] }),
      /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "flag_svg__a", children: /* @__PURE__ */ jsx(
        "path",
        {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        }
      ) }) })
    ]
  }
));
var Flag_default = SvgFlag;

export {
  Flag_default
};
