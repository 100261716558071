"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Notifications.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgNotifications = withPhosphorWrapper(
  (props) => /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "1em",
      height: "1em",
      fill: "none",
      viewBox: "0 0 32 32",
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "g",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 1.5,
            clipPath: "url(#notifications_svg__a)",
            children: /* @__PURE__ */ jsx("path", { d: "M21.25 9.438h-10.5c-.966 0-1.75.727-1.75 1.624v17.876c0 .897.784 1.625 1.75 1.625h10.5c.966 0 1.75-.728 1.75-1.625V11.063c0-.898-.784-1.626-1.75-1.626ZM9 12.688h14M9 27.313h14M16 4.75v-4M21 6l2.121-2.121M11 6 8.878 3.879" })
          }
        ),
        /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "notifications_svg__a", children: /* @__PURE__ */ jsx(
          "path",
          {
            fill: "#fff",
            d: "M0 0h32v32H0z"
          }
        ) }) })
      ]
    }
  )
);
var Notifications_default = SvgNotifications;

export {
  Notifications_default
};
